import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { getTextCowType } from '../util/cowTypes';
import { cost, costPerKilo } from '../../util/number';
import CowListItemAttributes from '../list/components/CowListItemAttributes';
import Button from '../../util/buttons/components/Button';
import './cow-main-details.scss';
import HeartIcon from '../../util/icons/components/HeartIcon';
import { setVideoPlaying } from '../actions';
import { getVideoPlaying } from '../reducer';
import RegisterInterestModal from '../../user/components/RegisterInterestModal';
import { markFavourite } from '../../user/actions';
import AuthenticationIcon from '../../util/icons/components/AuthenticationIcon';
import CalfDueSection from '../list/components/CalfDueSection';
import CowMainImage from './CowMainImage';
import KeyFactsTickList from '../list/components/KeyFactsTickList';

const mapDispatchToProps = dispatch => ({
  setVideoPlaying: (play) => dispatch(setVideoPlaying(play)),
  favourite: (cow, isFavourite) => dispatch(markFavourite(cow, isFavourite))
});

const mapStateToProps = state => ({
  videoPlaying: getVideoPlaying(state)
});

class CowMainDetailsSection extends Component {
  constructor (props) {
    super(props);
    this.state = {
      registering: false
    };
    this.playVideo = this.playVideo.bind(this);
    this.stopVideo = this.stopVideo.bind(this);
    this.registerInterest = this.registerInterest.bind(this);
    this.toggleFavourite = this.toggleFavourite.bind(this);
    this.hideRegisterInterest = this.hideRegisterInterest.bind(this);
  }
  registerInterest () {
    this.setState({ registering: true });
  }
  hideRegisterInterest () {
    this.setState({ registering: false });
  }
  toggleFavourite () {
    this.props.favourite(this.props.cow, !this.props.cow.favourite);
  }
  renderAuthenticationSection () {
    const documents = Object.values(this.props.cow.documents || {});
    if (documents && documents.length) {
      return (
        <Link to={ `${this.props.match.url}/documents` } >
          <div className="authentication-section">
            <AuthenticationIcon />
            <h5 className="authentication-title">Authenticated</h5>
            <p className="num-documents" data-cy="headline-doc-count">
              { documents.length + ' documents' }
            </p>
          </div>
        </Link>
      );
    }
    return null;
  }
  playVideo () {
    this.props.setVideoPlaying(true);
    const videoSection = document.getElementById('cow-video-section');
    if (videoSection) {
      videoSection.scrollIntoView({ behavior: 'smooth' });
    }
  }
  stopVideo () {
    this.props.setVideoPlaying(false);
  }
  renderDam () {
    if (this.props.cow.ancestors && this.props.cow.ancestors.dam) {
      return (
        <div className='parent' data-cy="parent-dam">
          <span className="title">Dam: </span>
          {this.props.cow.ancestors.dam.name}
        </div>
      );
    }
    return null;
  }
  renderSire () {
    if (this.props.cow.ancestors && this.props.cow.ancestors.sire) {
      return (
        <div className='parent' data-cy="parent-sire">
          <span className="title">Sire: </span>
          {this.props.cow.ancestors.sire.name}
        </div>
      );
    }
    return null;
  }
  renderAncestryLink () {
    const scrollToAncestry = () => {
      const ancestrySection = document.getElementById('cow-family-tree');
      if (ancestrySection) {
        ancestrySection.scrollIntoView({ behavior: 'smooth' });
      }
    };
    if (this.props.cow.ancestors) {
      if (this.props.cow.ancestors.sire || this.props.cow.ancestors.dam) {
        return (
          <div className="ancestry-link" onClick={scrollToAncestry} >
            View full ancestry
          </div>
        );
      }
    }
    return null;
  }
  renderRegisterInterestModal () {
    if (!this.state.registering) return null;
    return (
      <RegisterInterestModal
        cow={this.props.cow}
        close={this.hideRegisterInterest} />
    );
  }
  renderRegisterInterestButton () {
    if (this.props.cow.availabilityStatus === 'SOLD') {
      return (
        <div className="sold-text">sold</div>
      );
    }
    if (this.props.cow.interestRegistered) {
      return (
        <div className="be-in-touch">
          { 'Interest registered' }
        </div>
      );
    }
    return (
      <Button
        onClick={this.registerInterest}
        testId="purchase-inquiry-button"
        label='Purchase Inquiry' />
    );
  }
  renderPrice () {
    if (this.props.cow.type === 'FinishedCattle') {
      if (this.props.cow.pricePerKilo) {
        return (
          <div className='price'>
            <span className="smaller">
              {costPerKilo.format(this.props.cow.pricePerKilo / 100)}
            </span>
            <span className="per-kilo">/kg</span>
            <span className="carcass-weight">carcass weight</span>
          </div>
        );
      }
    }
    return (
      <div className='price' data-cy="headline-price">
        {cost.format(this.props.cow.price / 100)}
      </div>
    );
  }
  render () {
    const documents = this.props.cow ? this.props.cow.documents : {};
    return (
      <article className='bb-page-wrapper' id='cow-main-details-section'>
        { this.renderRegisterInterestModal() }
        <div className="cow-detail-section-wrapper">
          <CowMainImage cow={ this.props.cow } />
          <div className='top-section'>
            <h5 >
              <span className='breed' data-cy="breed-header">
                { this.props.cow.breed }
              </span>
              <span className="pipe">|</span>
              <span className='offering' data-cy="cow-type-header">
                {getTextCowType(this.props.cow.type)}
              </span>
            </h5>
            <HeartIcon
              onClick={this.toggleFavourite}
              className={this.props.cow.favourite ? 'selected' : null} />
            <h3 data-cy="animal-name-header">{this.props.cow.name}</h3>
            { this.props.cow.calfDue ? <CalfDueSection { ...this.props.cow } />
              : null}
            <h6 className="tag" data-cy="animal-tag-header">
              { this.props.cow.tag }
              <span className="pipe">|</span>
              <span className="location" data-cy="animal-location-header">
                { this.props.cow.location }
              </span>
            </h6>
            <CowListItemAttributes cow={this.props.cow} />
            <KeyFactsTickList documents={ documents } />
            { this.renderSire() }
            { this.renderDam() }
            { this.renderAncestryLink() }
          </div>
          <div className="bottom-section">
            <div className='price-section'>
              { this.renderPrice() }
              { this.renderRegisterInterestButton() }
            </div>
            <div className="authentication-wrapper">
              { this.renderAuthenticationSection() }
            </div>
          </div>
        </div>
        <div className="border"></div>
      </article>
    );
  }
}

CowMainDetailsSection.propTypes = {
  cow: PropTypes.object.isRequired,
  setVideoPlaying: PropTypes.func.isRequired,
  videoPlaying: PropTypes.bool.isRequired,
  favourite: PropTypes.func.isRequired,
  match: PropTypes.shape({
    url: PropTypes.string
  })
};

export default connect(
  mapStateToProps, mapDispatchToProps
)(CowMainDetailsSection);
