import React from 'react';
import PropTypes from 'prop-types';
import Donut from '../../util/charts/components/LargeRankedDoubleTitleDonut';
import './genetic-section.scss';
import BackToTop from '../../util/buttons/components/BackToTop';

const getSRIRanking = score => {
  if (score >= 59) return 5;
  if (score >= 55) return 10;
  if (score >= 50) return 20;
  if (score >= 44) return 40;
  return 100;
};

const getTIRanking = score => {
  if (score >= 45) return 5;
  if (score >= 43) return 10;
  if (score >= 39) return 20;
  if (score >= 32) return 40;
  return 100;
};

const getGradeFromRank = rank => {
  let grade = 'E';
  if (rank >= 95) {
    grade = 'A';
  } else if (rank >= 90) {
    grade = 'B';
  } else if (rank >= 80) {
    grade = 'C';
  } else if (rank >= 60) {
    grade = 'D';
  }
  return grade;
};

const GeneticSection = ({ cow }) => {
  let sriRank;
  let tiRank;
  let grade;
  try {
    sriRank = getSRIRanking(cow.EBV.SRIscore);
    tiRank = getTIRanking(cow.EBV.TIscore);
    grade = getGradeFromRank(100 - parseInt(cow.EBV.rank.replace('%', '')));
  } catch (error) { /* */ }
  return (
    <article id='genetic-section' className='bb-page-wrapper'>
      <BackToTop />
      <h2>Genetic Value</h2>
      <div className="content">

        <div className="line"></div>
        <div className={`combined-score grade-${grade}`}>
          <div className="rank">
            top<br/>
            <span className="number">{ cow.EBV.rank }</span>
          </div>
        </div>
        <Donut ranking= { sriRank }
          scoreText={ cow.EBV.SRIscore }
          title="Self Replacing" subtitle="Index"/>
        <Donut ranking= { tiRank }
          scoreText={ cow.EBV.TIscore }
          title="Terminal" subtitle= "Index"/>
      </div>
    </article>
  );
};

GeneticSection.propTypes = {
  cow: PropTypes.object
};

export default GeneticSection;
