import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { hasPhoneOrEmail, getPhone, getEmail } from '../reducer';
import { registerInterest } from '../actions';
import TickIcon from '../../util/icons/components/TickIcon';
import Button from '../../util/buttons/components/Button';
import CloseIcon from '../../util/icons/components/CloseIcon';
import './register-interest-modal.scss';
import analytics from '../../util/analytics';
import { validateEmail } from '../../util/email';
import validatePhone from '../../util/validatePhone';

const mapStateToProps = state => ({
  haveUserDetails: hasPhoneOrEmail(state),
  phone: getPhone(state),
  email: getEmail(state)
});

const mapDispatchToProps = dispatch => ({
  register: (cow, email, phone, message) =>
    dispatch(registerInterest(cow, email, phone, message))
});

class RegisterInterestModal extends Component {
  constructor (props) {
    super(props);
    this.state = {
      haveUserDetails: this.props.haveUserDetails,
      email: props.email || null,
      phone: props.phone || null,
      message: null,
      emailValid: true,
      phoneValid: true,
      registered: false
    };
    this.modalRef = React.createRef();
    this.phoneRef = React.createRef();
    this.emailRef = React.createRef();
    this.closeRef = React.createRef();
    this.messageRef = React.createRef();
    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.handlePhoneChange = this.handlePhoneChange.bind(this);
    this.handleMessageChange = this.handleMessageChange.bind(this);
    this.handleSubmitClicked = this.handleSubmitClicked.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.trackSubmission = this.trackSubmission.bind(this);
  }

  componentDidMount () {
    document.addEventListener('mousedown', this.handleClickOutside);
    if (!this.closeRef || this.closeRef.current === null) return;
    this.closeRef.current.scrollIntoView({ behavior: 'smooth' });
  }

  trackSubmission () {
    let recorded = '';
    if (this.state.phone && this.state.phoneValid) {
      recorded = 'phone';
    }
    if (this.state.email && this.state.emailValid) {
      if (recorded) {
        recorded += ',email';
      } else {
        recorded = 'email';
      }
    }
    analytics('send', 'event', 'cowListItem', 'registerInterest', recorded);
  }

  componentWillUnmount () {
    document.removeEventListener('mousedown', this.handleClickOutside);
    if (this.state.registered === false && this.props.haveUserDetails) {
      this.trackSubmission();
      this.props.register(this.props.cow, this.props.email, this.props.phone);
    }
  }

  handleClickOutside (event) {
    if (this.modalRef.current &&
    !this.modalRef.current.contains(event.target)) {
      this.props.close();
    }
  }

  handlePhoneChange () {
    const phone = this.phoneRef.current.value;
    let phoneValid = validatePhone(phone);
    if (phone === '') phoneValid = true;
    this.setState({ phone, phoneValid });
  }

  handleEmailChange () {
    const email = this.emailRef.current.value.toLowerCase();
    let emailValid = validateEmail(email);
    if (email === '') emailValid = true;
    this.setState({ email, emailValid });
  }

  handleMessageChange () {
    const message = this.messageRef.current.value;
    this.setState({ message });
  }

  handleSubmitClicked () {
    if ((this.state.email || this.state.phone) &&
    this.state.phoneValid && this.state.emailValid) {
      this.trackSubmission();
      this.props.register(
        this.props.cow, this.state.email, this.state.phone, this.state.message
      );
      this.setState({ haveUserDetails: true, registered: true });
    }
  }

  handleEmailBlur () {

  }

  canSubmit () {
    return (
      (this.state.email || this.state.phone) &&
      this.state.phoneValid && this.state.emailValid
    );
  }

  renderForm () {
    if (this.state.haveUserDetails) return null;
    const emailTickClass = this.state.email && this.state.emailValid
      ? 'tick-radio ticked' : 'tick-radio';
    const phoneTickClass = this.state.phone && this.state.phoneValid
      ? 'tick-radio ticked' : 'tick-radio';
    const messageTickClass = this.state.message ?
      'tick-radio ticked' : 'tick-radio';
    const buttonClass = this.canSubmit() ? null : 'disabled';
    const msgPlaceholder =
      'you can leave message or tell us the right time to call';
    return (
      <Fragment>
        <h2 className='title' data-cy="purchase-enquiry-title">Sales Desk</h2>
        <p className='strapline'>
          { `If you want to leave an email or phone number, we'll 
            get back to you as soon as possible` }
        </p>
        <form>
          <div className={ emailTickClass } ><TickIcon /></div>
          <input
            placeholder='email'
            value={this.state.email || '' }
            ref={this.emailRef}
            data-cy="email-input"
            className={this.state.emailValid ? '' : 'error'}
            onChange={this.handleEmailChange} />
          <div className={ phoneTickClass } ><TickIcon /></div>
          <input
            placeholder='phone'
            value={this.state.phone || ''}
            ref={this.phoneRef}
            data-cy="phone-input"
            className={this.state.phoneValid ? '' : 'error'}
            onChange={this.handlePhoneChange} />
          <div className={ messageTickClass } ><TickIcon /></div>
          <textarea
            placeholder= { msgPlaceholder }
            value={ this.state.message || '' }
            ref={ this.messageRef }
            data-cy="message-input"
            onChange={ this.handleMessageChange } />
        </form>
        <Button
          className={buttonClass}
          testId="submit-button"
          onClick={this.handleSubmitClicked}
          label='send your details' />
      </Fragment>
    );
  }

  renderThanks () {
    if (!this.state.haveUserDetails) return null;
    return (
      <Fragment>
        <div className='tick'><TickIcon /></div>
        <p className='thankyou-text' data-cy="got-message-text">
          { 'Thanks, we\'ve got your details and we\'ll be in touch' }
        </p>
        <Button
          label='continue browsing'
          testId="continue-browsing-button"
          className='continue-browsing'
          onClick={this.props.close} />
      </Fragment>
    );
  }

  render () {
    const className = this.state.haveUserDetails ? 'modal finished' : 'modal';
    return (
      <div id='register-interest-modal'>
        <article ref={this.modalRef} className={className}>
          <div
            onClick={this.props.close}
            className="close-wrapper"
            ref={this.closeRef}>
            <CloseIcon testId="close-modal-icon"/>
          </div>
          { this.renderForm() }
          { this.renderThanks() }
        </article>
      </div>
    );
  }
}

RegisterInterestModal.propTypes = {
  haveUserDetails: PropTypes.bool,
  phone: PropTypes.string,
  email: PropTypes.string,
  register: PropTypes.func.isRequired,
  cow: PropTypes.object.isRequired,
  close: PropTypes.func
};

export default connect(
  mapStateToProps, mapDispatchToProps
)(RegisterInterestModal);
