import React from 'react';
import PropTypes from 'prop-types';

/* eslint-disable max-len */
const ChevronIcon = ({ className, onClick }) => (
  <svg className={ className ? 'bb-chevron-icon ' + className : 'bb-chevron-icon' }
    onClick={ onClick }
    viewBox="0 0 60.03 98.53">
    <path d="M61,50a3.6,3.6,0,0,1-1.25,2.72L6.92,98.39A3.59,3.59,0,0,1,2.23,93L51.94,50,2.23,7A3.59,3.59,0,0,1,6.92,1.61L59.77,47.28A3.62,3.62,0,0,1,61,50Z" transform="translate(-0.98 -0.74)"/>
  </svg>
);

ChevronIcon.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func
};

export default ChevronIcon;
