export const cost = new Intl.NumberFormat(
  'en-GB', {
    style: 'currency',
    currency: 'GBP',
    minimumFractionDigits: 0
  }
);
export const costPerKilo = new Intl.NumberFormat(
  'en-GB', {
    style: 'currency',
    currency: 'GBP',
    minimumFractionDigits: 2
  }
);
