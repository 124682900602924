import React from 'react';
import PropTypes from 'prop-types';

const Button = ({ label, onClick, className, testId }) => (
  <div
    onClick={onClick}
    data-cy={ testId }
    className={ className ? 'bb-button ' + className : 'bb-button'}>
    { label }
  </div>
);

Button.propTypes = {
  label: PropTypes.string,
  onClick: PropTypes.func,
  className: PropTypes.string,
  testId: PropTypes.string
};

export default Button;
