import React from 'react';
import { dueInXMonths } from '../../../util/datesAndAges';
import PropTypes from 'prop-types';
import './in-calf-section.scss';

const CalfDueSection = ({ calfDue, calfSire }) => {
  if (!calfDue || !calfSire) return null;
  return (
    <div className="calf-due-section">
      <span className="in-calf">In calf, </span>
      <span className="due-in">{ dueInXMonths(new Date(calfDue)) }, </span>
      <a href={ `/cattle/young-bulls/${calfSire}/sire` } className="sire-link">
        View Sire
      </a>
    </div>
  );
};

CalfDueSection.propTypes = {
  calfDue: PropTypes.string,
  calfSire: PropTypes.string
};

export default CalfDueSection;
