import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import './logo.scss';

const Logo = ({ noLink }) => {
  if (noLink) {
    return (
      <div className='bb-main-logo'>
        <span className='logo-beef'>
          Beef
        </span><span className='logo-book'>
          Book
        </span>
      </div>
    );
  }
  return (
    <Link className="logo-wrapper" to='/'>
      <div className='bb-main-logo' data-cy="bb-main-logo">
        <span className='logo-beef'>
          Beef
        </span><span className='logo-book'>
          Book
        </span>
      </div>
    </Link>
  );
};

Logo.propTypes = {
  noLink: PropTypes.bool
};

export default Logo;
