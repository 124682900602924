import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import GoodBeefInfo from '../../content/infoblocks/GoodBeefInfo';
import GBIGradeShield from './GBIGradeShield';
import Modal from '../../main/components/Modal';
import { getGradeFromScore } from '../../admin/scoreDefinitions/util';

class GBIGradeShieldAndConceptModal extends Component {
  constructor (props) {
    super(props);
    this.state = {
      showingModal: false,
      startPosition: null
    };
    this.showModal = this.showModal.bind(this);
    this.hideModal = this.hideModal.bind(this);
  }
  showModal (event) {
    event.stopPropagation();
    event.preventDefault();
    this.setState({
      showingModal: true,
      startPosition: window.scrollY
    });
  }
  hideModal () {
    this.setState({ showingModal: false }, () => {
      window.scrollTo({
        top: this.state.startPosition,
        behavior: 'smooth'
      });
    });
  }
  render () {
    const modal = this.state.showingModal ? (
      <Modal
        contentClass='gbi-info-popout'
        close={ this.hideModal }
        pos={ this.state.startPosition }>
        <h4 className='mini-title'>find out more...</h4>
        <GoodBeefInfo score={ this.props.score }/>
      </Modal>
    ) : null;
    return (
      <Fragment>
        { modal }
        <div
          onClick={ this.showModal }>
          <GBIGradeShield grade={ getGradeFromScore(this.props.score) } />
        </div>
      </Fragment>
    );
  }
}

GBIGradeShieldAndConceptModal.propTypes = {
  score: PropTypes.number.isRequired
};

export default GBIGradeShieldAndConceptModal;
