import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './cow-media-selector.scss';
import ChevronIcon from '../../util/icons/components/ChevronIcon';

class CowMediaSelector extends Component {
  constructor (props) {
    super(props);
    this.state = {
      index: 0
    };
    this.scrollLeft = this.scrollLeft.bind(this);
    this.scrollRight = this.scrollRight.bind(this);
  }
  scrollLeft () {
    if (this.state.index > 0) {
      this.setState({ index: this.state.index - 1 });
    }
  }
  scrollRight () {
    if (this.state.index < this.props.images.length - 5) {
      this.setState({ index: this.state.index + 1 });
    }
  }
  calcImageStyle (imageObj) {
    let imgSrc = '';
    const percentage = this.state.index * 100;
    const adjust = this.state.index * 10;
    try {
      imgSrc = imageObj.url;
    } catch (error) { /* */ }
    return {
      backgroundImage: `url('${imgSrc}')`,
      transform: `translateX(calc(-${percentage}% - ${adjust}px))`
    };
  }
  render () {
    const elements = this.props.images.map((image, index) => {
      console.log(this.calcImageStyle(image));
      return (
        <div key={image.url}
          style={ this.calcImageStyle(image) }
          className='image-select-item'
          onClick={() => this.props.selectImage(index)} ></div>
      );
    });
    let leftArrowClass = 'arrow left';
    let rightArrowClass = 'arrow right';
    if (this.state.index === 0) leftArrowClass += ' disabled';
    if (this.state.index >= this.props.images.length - 5) {
      rightArrowClass += ' disabled';
    }
    return (
      <div id='cow-media-selector'>
        <div className={ leftArrowClass } onClick={this.scrollLeft}>
          <ChevronIcon />
        </div>
        <div className='images'>
          { elements }
        </div>
        <div className={ rightArrowClass } onClick={this.scrollRight}>
          <ChevronIcon />
        </div>
      </div>
    );
  }
}

CowMediaSelector.propTypes = {
  current: PropTypes.number.isRequired,
  selectImage: PropTypes.func.isRequired,
  playVideo: PropTypes.func.isRequired,
  images: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string.isRequired
    })
  )
};

export default CowMediaSelector;
