import React from 'react';
import PropTypes from 'prop-types';
import './heart-icon.scss';

/* eslint-disable max-len */
const HeartIcon = ({ className, onClick, testId }) => (
  <svg
    onClick={onClick}
    data-cy={ testId }
    className={ className ? className + ' bb-heart-icon' : 'bb-heart-icon' }
    viewBox="0 0 98.29 85">
    <path className="background" d="M49.85,85.63a3.35,3.35,0,0,1-2.37-1L8.39,45.44A26.25,26.25,0,1,1,49.85,14.05,26.25,26.25,0,1,1,91.31,45.44L52.23,84.64A3.36,3.36,0,0,1,49.85,85.63Z" transform="translate(-0.71 -0.63)"/><path className="center" d="M27,7.34A19.54,19.54,0,0,0,13.14,40.7L49.85,77.52,86.56,40.7A19.54,19.54,0,1,0,53.21,26.88a3.36,3.36,0,0,1-6.71,0A19.56,19.56,0,0,0,27,7.34Z" transform="translate(-0.71 -0.63)"/>
  </svg>
);

HeartIcon.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
  testId: PropTypes.string
};

export default HeartIcon;
