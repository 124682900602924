import React, { Fragment } from 'react';
import { Route } from 'react-router-dom';
import Cow from './Cow';
import CowScoreCard from './CowScoreCard';
import PropTypes from 'prop-types';
import Documents from './Documents';

const CowDetails = ({ match }) => {
  return (
    <Fragment>
      <Route path={ match.url + '/documents' }
        render={
          props =>
            <Documents { ...props} cowId={match.params.cowId} />
        } />
      <Route path={ match.url + '/scorecard/:attribute' }
        render={
          props =>
            <CowScoreCard { ...props} cowId={match.params.cowId} />
        } />
      <Route
        path={ match.url }
        render={
          props =>
            <Cow { ...props } match={match} />
        } />
    </Fragment>
  );
};

CowDetails.propTypes = {
  match: PropTypes.object.isRequired
};

export default CowDetails;
