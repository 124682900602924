//  https://codepen.io/matttherat/pen/EeMaEw

/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import './large-ranked-double-title-donut.scss';
import './donut-chart-keyframes.scss';

const LargeDonut = ({ ranking, scoreText, title, subtitle }) => {
  const score = ranking;
  const percentage = 100 - score;
  const segmentStyle = {
    animation: `donut${percentage} ${score * 20 * 2}ms ease-out`
  };
  const segmentDasharray = `${percentage} ${100 - percentage}`;
  const strokeWidth = 2;
  let rank = 'E';
  if (percentage > 95) {
    rank = 'A';
  } else if (percentage > 90) {
    rank = 'B';
  } else if (percentage > 80) {
    rank = 'C';
  } else if (percentage > 60) {
    rank = 'D';
  }
  const className = ' large-ranked-donut ' + rank + '-rank';
  return (
    <div className={ className }>
      <svg viewBox='0 0 40 40' className='donut'>
        <circle className='donut-hole' cx='20' cy='20' r='15.91549430918954' fill='#fff'></circle>
        <circle className='donut-ring' cx='20' cy='20' r='15.91549430918954' fill='transparent' strokeWidth={strokeWidth}></circle>
        <circle style={segmentStyle} className='donut-segment donut-segment-2' cx='20' cy='20' r='15.91549430918954' fill='transparent' strokeWidth={strokeWidth} strokeDasharray={segmentDasharray} strokeDashoffset='25'></circle>
        <g className='donut-content'>
          <text y="52%" transform="translate(0, 2)">
            <tspan x='50%' y="45%" textAnchor='middle' className='donut-score'>
              { scoreText }
            </tspan>
            <tspan x="50%" y="55%" textAnchor="middle" className="donut-title">
              { title }
            </tspan>
            <tspan x="50%" y="65%" textAnchor="middle" className="donut-subtitle">
              { subtitle }
            </tspan>
          </text>
        </g>
      </svg>
    </div>
  );
};

LargeDonut.propTypes = {
  ranking: PropTypes.number,
  subtitle: PropTypes.string,
  title: PropTypes.string,
  scoreText: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ])
};

export default LargeDonut;
