import React from 'react';
import PropTypes from 'prop-types';
import docsObjToArray from '../../../admin/documents/util/docsObjToArray';
import TickIcon from '../../../util/icons/components/TickIcon';
import './key-facts-tick-list.scss';

const KeyFactsTickList = ({ documents }) => {
  if (!documents) return null;
  const facts = docsObjToArray(documents)
    .slice(0, 3)
    .map(doc => {
      return (
        <a href={ doc.src }
          target="_blank"
          className="badge"
          key={ doc.key }
          rel="noopener noreferrer"
          data-cy={ `key-fact-${doc.key}` }>
          <TickIcon />
          { doc.keyFact }
        </a>
      );
    });
  return (
    <div className="bb-key-verification-facts"
      data-cy="key-facts-list">
      { facts }
    </div>
  );
};

KeyFactsTickList.propTypes = {
  documents: PropTypes.object
};

export default KeyFactsTickList;
